import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Testimonials } from "../components/testimonials"
import { Cta } from "../components/cta"
import { FeatureQuality } from "../components/feature-quality"
import { FeatureMoney } from "../components/feature-money"
import { FeatureTime } from "../components/feature-time"
import { Cta2 } from "../components/cta-2"
import { HeaderIndex } from "../components/header-index"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Product development"
      keywords={[
        `software`,
        `application`,
        `applications`,
        "development",
        "developer",
        "programming",
        "programmer",
        "mobile",
        `react`,
      ]}
    />
    <HeaderIndex />
    <Testimonials />
    <Cta />

    <FeatureQuality />
    <FeatureMoney />
    <FeatureTime />

    <Cta2 />
  </Layout>
)

export default IndexPage
