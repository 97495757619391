import React from "react"
import Container from "./container"
import css from "@emotion/css"
import { FeatureTitle, FeatureContent } from "./feature"
import FeatureMoneyImage from "../images/feature-money.svg"
import { useViewport } from "../hooks/use-viewport-width"

export const FeatureMoney = () => {
  const viewport = useViewport()
  return (
    <Container
      css={css`
        align-items: start;
        margin-bottom: 60px;
        ${!viewport.isNarrowPlus ? "flex-direction: column;" : ""}
      `}
    >
      <div
        css={css`
          width: 100%;
          ${!viewport.isNarrowPlus
            ? "max-width: 250px;"
            : `margin-right: ${viewport.isMediumPlus ? "-" : ""}50px;`}
        `}
      >
        <img
          className="img-responsive"
          src={FeatureMoneyImage}
          alt="Money Illustration"
        />
      </div>
      <div>
        <FeatureTitle>
          Don't reinvent the wheel. Improve what's already been done.
        </FeatureTitle>
        <FeatureContent>
          <p>
            We focus on a smaller number of technologies to optimize our work
            process. We've got a lot of experience that we can build upon.
            Without building the same thing twice.
          </p>
        </FeatureContent>
      </div>
    </Container>
  )
}
