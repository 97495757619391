import React from "react"
import Container from "./container"
import { Button } from "./button"
import css from "@emotion/css"
import { Link } from "gatsby"
import { CtaTitle } from "./cta"

export const Cta2 = () => (
  <div
    css={css`
      padding-top: 100px;
    `}
  >
    <Container
      css={css`
        flex-direction: column;
      `}
    >
      <CtaTitle
        css={css`
          margin-bottom: 60px;
          text-align: center;
        `}
      >
        Get more features in less time
      </CtaTitle>
      <Link to="/contact?ref=cta2">
        <Button
          css={css`
            padding: 12px 42px;
            font-size: 18px;
          `}
        >
          Get a quotation
        </Button>
      </Link>
    </Container>
  </div>
)
