import React from "react"
import Container from "./container"
import css from "@emotion/css"
import { FeatureTitle, FeatureContent } from "./feature"
import FeatureQualityImage from "../images/feature-quality.svg"
import { useViewport } from "../hooks/use-viewport-width"

export const FeatureQuality = () => {
  const viewport = useViewport()
  return (
    <Container
      css={css`
        ${!viewport.isNarrowPlus ? "flex-direction: column;" : ""}
        align-items: start;
        margin-bottom: 60px;
      `}
    >
      <div>
        <FeatureTitle>
          Focus on quality. Deliver applications people love to use.
        </FeatureTitle>
        <FeatureContent>
          <p>
            We hire talented and passionate developers that are in touch with
            latest technology.
          </p>

          <p>
            Don't worry about misunderstanding your requirements. We care about
            the importance of communication with the client.
          </p>
        </FeatureContent>
      </div>
      <div
        css={css`
          width: 100%;
          ${!viewport.isNarrowPlus
            ? "max-width: 250px; order: -1;"
            : `margin-left: ${viewport.isMediumPlus ? "-" : ""}50px;`}
        `}
      >
        <img
          className="img-responsive"
          src={FeatureQualityImage}
          alt="Quality Illustration"
        />
      </div>
    </Container>
  )
}
