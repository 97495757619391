import React from "react"
import styled from "@emotion/styled"
import Container from "./container"
import TestimonialsImage from "../images/testimonials.jpg"
import css from "@emotion/css"

const TestimonialsTitle = styled.h2``

const TestimonialsContainer = styled.div`
  margin-bottom: 60px;
`

export const Testimonials = () => (
  <TestimonialsContainer>
    <Container
      css={css`
        display: block;
        text-align: center;
      `}
    >
      <TestimonialsTitle>We work with wonderful companies</TestimonialsTitle>
      <img
        css={css`
          max-width: 100%;
          height: auto;
        `}
        width="618"
        src={TestimonialsImage}
        alt="Companies"
      />
    </Container>
  </TestimonialsContainer>
)
