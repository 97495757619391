import styled from "@emotion/styled"

export const FeatureTitle = styled.h3`
  margin-top: 60px;
`

export const FeatureContent = styled.div`
  font-size: 18px;
  color: #737376;
`
