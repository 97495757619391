import React from "react"
import { Header, HeaderTitle, HeaderSubtitle } from "./header"
import HeaderImage from "../images/header.svg"
import css from "@emotion/css"

export const HeaderIndex = () => {
  return (
    <Header
      renderText={viewport => (
        <>
          <HeaderTitle
            css={css`
              ${!viewport.isNarrowPlus ? "font-size: 32px;" : ""}
            `}
          >
            Deliver better products with specialized developers. Get higher
            quality for lower cost.
          </HeaderTitle>
          <HeaderSubtitle>
            Our focus on few technologies allows building high quality products
            the most effective way.
          </HeaderSubtitle>
        </>
      )}
      renderPicture={() => (
        <div
          css={css`
            width: 100%;
          `}
        >
          <img
            className="img-responsive"
            alt="Header Illustration"
            src={HeaderImage}
          />
        </div>
      )}
    />
  )
}
