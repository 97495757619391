import React from "react"
import Container from "./container"
import css from "@emotion/css"
import { FeatureTitle, FeatureContent } from "./feature"
import FeatureTimeImage from "../images/feature-time.svg"
import { useViewport } from "../hooks/use-viewport-width"

export const FeatureTime = () => {
  const viewport = useViewport()
  return (
    <Container
      css={css`
        align-items: start;
        ${!viewport.isNarrowPlus ? "flex-direction: column;" : ""}
      `}
    >
      <div>
        <FeatureTitle>
          Spend more time on things that actually matter
        </FeatureTitle>
        <FeatureContent>
          <p>
            By optimized development process we can spend less time on building
            standard features. Focus on features that make you better than your
            competitor.
          </p>
          <p>
            Our pre-built teams are ready to jump on your project. Don't get
            limited by resources.
          </p>
        </FeatureContent>
      </div>

      <div
        css={css`
          width: 100%;
          ${!viewport.isNarrowPlus
            ? "max-width: 250px; order: -1;"
            : `margin-left: ${viewport.isMediumPlus ? "-" : ""}50px;`}
        `}
      >
        <img
          className="img-responsive"
          src={FeatureTimeImage}
          alt="Time Illustration"
        />
      </div>
    </Container>
  )
}
