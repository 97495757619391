import React from "react"
import styled from "@emotion/styled"
import Container from "./container"
import { Button } from "./button"
import css from "@emotion/css"
import { Link } from "gatsby"
import { useViewport } from "../hooks/use-viewport-width"

export const CtaTitle = styled.div`
  font-size: 36px;
  color: #000;
`

const CtaContainer = styled.div`
  margin-bottom: 60px;
`

const CtaBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #eee;
  padding: 60px 80px;
`

export const Cta = () => {
  const viewport = useViewport()
  return (
    <CtaContainer>
      <Container>
        <CtaBox
          css={css`
            align-items: center;
            ${!viewport.isMediumPlus ? "flex-direction: column;" : ""}
            ${!viewport.isNarrowPlus ? "padding: 60px 20px;" : ""}
          `}
        >
          <CtaTitle
            css={css`
              text-align: center;
              ${!viewport.isMediumPlus
                ? "font-size: 28px; margin-bottom: 30px;"
                : ""}
            `}
          >
            Let us help you with your project
          </CtaTitle>
          <Link to="/contact?ref=cta1">
            <Button
              css={css`
                padding: 12px 42px;
                font-size: 18px;
              `}
            >
              Get in touch
            </Button>
          </Link>
        </CtaBox>
      </Container>
    </CtaContainer>
  )
}
